<template>
  <a-card class="card" :bordered="false">
    <div class="table-page-search-wrapper">
      <a-form layout="inline">
        <a-row :gutter="GLOBAL.queryRowGutter">
          <a-col v-bind="GLOBAL.queryColSpan">
            <a-form-item label="订单号">
              <a-input v-model.trim="queryParam.order_num" placeholder="根据订单号搜索" />
            </a-form-item>
          </a-col>
          <a-col v-bind="GLOBAL.queryColSpan">
            <a-form-item label="供应商">
              <a-select
                showSearch
                :filter-option="filterOption"
                allowClear
                placeholder="请选择购买单位"
                style="width: 100%"
                v-model="queryParam.buyer"
                @change="querySubmit"
              >
                <a-select-option v-for="op in buyerOps" :key="op.value">{{ op.name }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col v-bind="GLOBAL.queryColSpan">
            <a-form-item label="费用状态">
              <a-select
                allowClear
                placeholder="请选择费用状态"
                style="width: 100%"
                v-model="queryParam.fee_status"
                mode="multiple"
              >
                <a-select-option v-for="op in feeStatusList" :key="op.value">{{ op.name }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col v-bind="GLOBAL.queryColSpan">
            <a-form-item label="合计金额">
              <a-input-number v-model="queryParam.total_amount" placeholder="输入合计金额搜索" style="width: 100%" />
            </a-form-item>
          </a-col>
          <a-col v-bind="GLOBAL.queryColSpan">
            <a-form-item label="订单类型">
              <a-select
                v-model="queryParam.bill_type"
                allowClear
                placeholder="请选择订单类型"
                @change="querySubmit"
              >
                <a-select-option :value="0">进货</a-select-option>
                <a-select-option :value="2">代销</a-select-option>
                <a-select-option :value="4">贸易行政</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col v-bind="GLOBAL.queryColSpan">
            <a-form-item label="创建时间">
              <a-range-picker @change="onDateChange" />
            </a-form-item>
          </a-col>
          <a-col v-bind="GLOBAL.queryColSpan">
            <a-form-item label="品名">
              <a-input v-model.trim="queryParam.good_name" placeholder="根据品名进行搜索" />
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </div>
    <div class="table-operator">
      <a-button type="query" icon="search" @click="querySubmit">查询</a-button>
      <a-button type="primary" :disabled="!selectedRowKeys.length" @click="handleVerifyOrAudit('pay_basic_audit')">审核</a-button>
      <a-button type="primary" :disabled="!selectedRowKeys.length" @click="handleVerifyOrAudit('pay_undo_audit')">反审核</a-button>
      <a-button type="primary" :disabled="!selectedRowKeys.length" @click="handleApplyBizAudit()">商务审核</a-button>
      <a-button type="primary" @click="handleStaticsExport">导出代销月度统计表</a-button>
      <a-button type="primary" @click="handleAgentInfoExport">对账单</a-button>
    </div>
    <s-table
      ref="table"
      size="small"
      rowKey="order_num"
      :scroll="{ x: 'max-content' }"
      :columns="columns"
      :data="loadData"
      :alert="{
        show: true,
        msg: showTotal
      }"
      :rowSelection="{
        selectedRowKeys: this.selectedRowKeys,
        onChange: this.onSelectChange,
        onSelect: this.selectItem,
        onSelectAll: this.selectAllItem
      }">
      <span slot="serial" slot-scope="text, record, index">
        {{ index + 1 }}
      </span>
      <div slot="feeItem" slot-scope="fee">
        <div v-for="item in fee" :key="item.id">
          <a-popover trigger="click" v-if="item.deny_reason">
            <a-tag :color="feeStatusColor(item.fee_status)" style="margin-bottom:2px;">
              {{ GLOBAL.feeStatusMaps[item.fee_status] ? GLOBAL.feeStatusMaps[item.fee_status].name : '' }}
            </a-tag>
            <template slot="content">
              <div v-for="(reason, index) in item.deny_reason" :key="index">
                <div>驳回人:<a style="margin-right:10px;">{{ reason.deny_person_name }}</a>驳回原因:<a style="margin-right:10px;">{{ reason.deny_reason }}</a>驳回时间:<a>{{ reason.deny_date }}</a></div>
              </div>
            </template>
          </a-popover>
          <a-tag :color="feeStatusColor(item.fee_status)" style="margin-bottom:2px;" v-else>
            {{ GLOBAL.feeStatusMaps[item.fee_status] ? GLOBAL.feeStatusMaps[item.fee_status].name : '' }}
          </a-tag>
          <span>{{ `${item.good_name}/${item.unit}/${item.good_spec}` }} ~ 数量: {{ item.good_num }} 现金:{{ item.total_money }}</span>
        </div>
      </div>
      <div slot="openinvoiceItem" slot-scope="isopen">
        <div v-if="isopen === 1">
          <a-tag color="#4cd964" style="margin-bottom:2px;">
            已开票
          </a-tag>
        </div>
        <div v-if="isopen === 2">
          <a-tag color="#faad14" style="margin-bottom:2px;">
            未开票
          </a-tag>
        </div>
      </div>
      <div slot="fmsLogsItem" slot-scope="text">
        <a-tag :color="text === 1 ? '#4cd964' : '#faad14'">
          {{ text === 1 ? '已认领' : '未认领' }}
        </a-tag>
      </div>
    </s-table>

    <a-modal
      :visible="applyFormVisible"
      :confirmLoading="confirmLoading"
      title="商务审核申请"
      :width="720"
      @cancel="handleApplyFormCancel"
      @ok="handleApplyFormSubmit"
    >
      <a-form-model layout="vertical" :model="form" :rules="rules" ref="form">
        <a-divider orientation="left">基础信息</a-divider>
        <a-row :gutter="24">
          <a-col :span="8">
            <a-form-model-item label="付款方式" prop="pay_method">
              <a-select v-model="form.pay_method" @change="payMethodToRemark">
                <a-select-option :value="item.value" v-for="item in paymentList" :key="item.value">
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :span="8">
            <a-form-model-item label="是否预付" prop="is_advance">
              <a-select v-model="form.is_advance">
                <a-select-option :value="item.value" v-for="item in isActiveOps" :key="item.value">
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :span="8">
            <a-form-model-item label="附件数" prop="appendix_num">
              <a-input suffix="张" v-model="form.appendix_num"/>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-form-model-item label="发票号码" prop="invoice_number">
          <a-select
            showSearch
            mode="multiple"
            ref="invoice_number"
            :filter-option="filterOption"
            allowClear
            placeholder="请选择发票号码"
            style="width: 100%"
            v-model="form.invoice_num"
          >
            <a-select-option v-for="op in invoiceRegOps" :key="op.id"
            >发票号:{{ op.invoice_number }}/金额:{{ op.amount }}</a-select-option
            >
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="合同号" prop="contract_list">
          <a-select
            showSearch
            mode="multiple"
            ref="contract_list"
            :filter-option="filterOption"
            allowClear
            placeholder="请选择合同号"
            style="width: 100%"
            v-model="form.contract_list"
          >
            <a-select-option v-for="op in leaseInfoList" :key="op.id">
              合同号:{{ op.contract_no }}
              <span v-if="op.is_no_term===0 && !op.contract_termination_date">/ 截至日期:{{ moment(op.contract_end_date).format('YYYY/MM/DD') }}</span>
              <span v-if="op.is_no_term===0 && op.contract_termination_date">/顺延日期:{{ moment(op.contract_termination_date).format('YYYY/MM/DD') }}</span>
              / {{ op.customer_info.customer_cn_name }}
              <span v-if="op.credit_period">/ 信用期：{{ op.credit_period }}天</span>
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-divider orientation="left">费用信息
          <a-tag color="#f50" style="margin-left:10px;">合计:{{ totalAmount.toFixed(2) }}</a-tag>
        </a-divider>
        <a-form-model-item>
          <edit-table
            :columns="feeInfoColumns"
            :sourceData="feeInfoData"
            @getTableDate="getFeeInfoTableDate"
            :disabled="true"></edit-table>
        </a-form-model-item>
        <a-form-model-item label="申请备注" prop="apply_remark">
          <a-textarea v-model="form.apply_remark"></a-textarea>
        </a-form-model-item>
      </a-form-model>
    </a-modal>

    <!--选择付款对象-->
    <a-modal
      :visible="choosePayTargetVisible"
      :confirmLoading="choosePayTargetVisibleLodding"
      title="付款对象"
      :width="720"
      @cancel="choosePayTargetCancel"
      @ok="subPrintPayBills"
    >
      <a-divider orientation="left">选择付款对象</a-divider>
      <a-row>
        <a-col :span="8">
          <a-radio-group v-model="pay_target_Data">
            <a-radio :key="item.id" v-for="item in payTarget" :value="item">{{ item.pay_target_name }}{{ item.fee_name }}:{{ item.money }}{{ item.currency_name }}/发票号:{{ item.invoice_num }}</a-radio>
          </a-radio-group>
        </a-col>
      </a-row>
    </a-modal>

    <a-modal
      :visible="printPayBillModel"
      :confirmLoading="printPayBillModelLoading"
      :width="1520"
      :maskClosable="false"
      title="打印付款单"
      @cancel="printPayBillModel = false"
      @ok="printPayBillModel = false"
    >
      <div id="printPayBill">
        <pay-bill
          :orderData="printData"
          :type="orderType"
        >
        </pay-bill>
      </div>
      <a-button key="submit"
                type="primary"
                id="printPayBillBtn"
                v-print="printPayBill">确认打印</a-button>
    </a-modal>

    <a-modal
      :visible="printPayStatementsBillModel"
      :confirmLoading="printPayStatementsBillModelLoading"
      :width="1520"
      :maskClosable="false"
      title="打印付款对账单"
      @cancel="printPayStatementsBillModel = false"
      @ok="printPayStatementsBillModel = false"
    >
      <div id="printStatementsPayBill">
        <pay-statement-bill
          :orderData="printData"
          :type="orderType"
        >
        </pay-statement-bill>
      </div>
      <a-button key="submit"
                type="primary"
                id="printStatementsPayBtn"
                v-print="printStatementsPayBill">确认打印</a-button>
    </a-modal>
  </a-card>
</template>

<script>
import { STable, EditTable } from '@/components'
import { getFirstLetter } from '@/utils/util'
import { getCommonNoPage, getSupplier, downloadExcel, getLeaseInfoListByDepartment } from '@/api/common'
import { getPurchaseSaleFinancePage, getPurchaseSaleFinanceTotal, wmsSaleFinanceChargeFunc, exportWmsSaleFinance, wmsSaleFinanceFunc } from '@/api/wms'
import { exportMonthlyStatisticsData, exportAgentInfoExport } from '@/api/wms/sale'
import payBill from '@/views/components/PayBill';
import payStatementBill from '@/views/components/PayStatementBill';
import moment from 'moment'

export default {
  components: {
    STable,
    payBill,
    payStatementBill,
    EditTable
  },
  data() {
    return {
      queryParam: {
        type: 0
      },
      customerOps: [],
      buyerOps: [],
      feeOrderList: [],
      totalAmount: 0,
      checkedList: [],
      indeterminate: false,
      checkAll: true,
      columns: [
        {
          title: '#',
          scopedSlots: { customRender: 'serial' },
          align: 'center'
        },
        {
          title: '类型',
          dataIndex: 'type',
          customRender: text => {
            switch (text) {
              case 0: return '进货'
              case 2: return '代销'
              case 4: return '贸易行政'
              default: return ''
            }
          }
        },
        {
          title: '订单号',
          dataIndex: 'order_num',
          align: 'center'
        },
        {
          title: '费用',
          dataIndex: 'fee_info_list',
          scopedSlots: { customRender: 'feeItem' }
        },
        {
          title: '资金认领状态',
          dataIndex: 'fms_capital_claim_log_flag',
          scopedSlots: { customRender: 'fmsLogsItem' }
        },
        {
          title: '应收开票状态',
          dataIndex: 'is_openinvoice',
          scopedSlots: { customRender: 'openinvoiceItem' }
        },
        {
          title: '供应商',
          dataIndex: 'customer_name',
          align: 'center'
        },
        {
          title: '创建时间',
          dataIndex: 'order_date'
        },
        {
          title: '合计',
          dataIndex: 'total'
        },
        {
          title: '备注',
          dataIndex: 'remark'
        }
      ],
      loadData: parameter => {
        return getPurchaseSaleFinancePage(Object.assign(parameter, this.queryParam)).then(res => {
          return res
        })
      },
      showTotal: '应付总价合计:0',
      payTotal: 0, // 应付总价合计
      selectedRowKeys: [],
      selectedRows: [],
      paymentList: [
        {
          value: 1, name: '转账'
        },
        {
          value: 2, name: '支票'
        },
        {
          value: 3, name: '代付'
        },
        {
          value: 4, name: '打卡'
        },
        {
          value: 5, name: '现金'
        },
        {
          value: 6, name: '内部结算'
        },
        {
          value: 7, name: '免付款'
        }
      ],
      isActiveOps: [
        {
          value: 1, name: '非预付'
        },
        {
          value: 2, name: '预付'
        }
      ],
      feeStatusList: [
        { value: 1, name: `待审核` },
        { value: 2, name: `业务已审核` },
        { value: 3, name: `商务待审核` },
        { value: 4, name: `商务审核被驳回` },
        { value: 9, name: '商务已审核(待付款)' },
        { value: 13, name: '已付款' }
      ],
      confirmLoading: false,
      applyFormVisible: false,
      choosePayTargetVisible: false,
      choosePayTargetVisibleLodding: false,
      payTarget: [],
      pay_target_Data: {},
      orderData: {},
      payBillName: '',
      form: {
        pay_method: '',
        apply_remark: '',
        appendix_num: null,
        invoice_num: [],
        is_advance: 1,
        contract_list: []
      },
      userInfo: this.$store.getters.userInfo,
      rules: {
        apply_remark: [{ required: true, message: '请输入申请备注', trigger: 'blur' }],
        pay_method: [{ required: true, message: '请选择付款方式', trigger: 'change' }]
      },
      printPayStatementsBillModel: false,
      printPayStatementsBillModelLoading: false,
      printStatementsPayBill: {
        id: 'printStatementsPayBill',
        popTitle: '',
        extraCss: '',
        extraHead: '<meta http-equiv="Content-Language"content="zh-cn"/>'
      },
      printPayBillModel: false,
      printPayBillModelLoading: false,
      printPayBill: {
        id: 'printPayBill',
        popTitle: '',
        extraCss: '',
        extraHead: '<meta http-equiv="Content-Language"content="zh-cn"/>'
      },
      printData: [],
      orderType: '贸易部',
      invoiceRegOps: [],
      leaseInfoList: [],
      feeInfoColumns: [
        {
          title: `费用名称`,
          dataIndex: `feeName`,
          extra: {
            type: `text`
          }
        },
        {
          title: `货物数量`,
          dataIndex: `good_num`,
          width: 120,
          extra: {
            type: `number`,
            extraFunc: this.calcTotal
          }
        },
        {
          title: `金额`,
          dataIndex: `money`,
          extra: {
            type: `text`
          }
        }
      ],
      feeInfoData: []
    }
  },
  created() {
    getSupplier({ department: 8 }).then(res => {
      this.buyerOps = res
    });
    this.getPayTotal()
  },
  watch: {
    selectedRows: function() {
      if (this.selectedRows.length !== 0) {
        this.payTotal = 0;
        const data = this.queryParam['fee_status'];
        this.selectedRows.forEach(v => {
          v.fee_info_list.forEach(fee => {
            if (data && data.length > 0) {
              if (data.includes(fee.fee_status)) {
                this.payTotal += fee.money;
              }
            } else {
              this.payTotal += fee.money;
            }
          });
        });
      } else {
        this.payTotal = 0;
      }
      this.showTotal = '应付总价合计:' + this.payTotal.toFixed(2);
    }
  },
  methods: {
    moment,
    // 查询
    querySubmit() {
      this.$refs.table.refresh(true)
      this.getPayTotal()
    },
    getPayTotal() {
      getPurchaseSaleFinanceTotal(this.queryParam).then(res => {
        this.showTotal = '应付总价合计:' + res.toFixed(2)
      })
    },
    calcTotal(value, newData, target) {
      let money = 0;
      this.feeInfoData.forEach(v => {
        money += parseFloat((v.money * v.good_num).toFixed(2));
      })
      this.totalAmount = money;
      return newData;
    },
    selectItem(record, selected, selectedRows) {
      if (selected) {
        let flag = true
        for (let index = 0; index < this.selectedRows.length; index++) {
          if (this.selectedRows[index].order_num === record.order_num) {
            flag = false
          }
        }
        if (flag) {
          this.selectedRows.push(record)
        }
      } else {
        for (let index = 0; index < this.selectedRows.length; index++) {
          if (this.selectedRows[index].order_num === record.order_num) {
            this.selectedRows.splice(index, 1)
          }
        }
      }
    },
    selectAllItem(selected, selectedRows, changeRows) {
      if (selected) {
        changeRows.forEach(data => {
          let flag = true
          this.selectedRows.forEach(item => {
            if (data.order_num === item.order_num) {
              flag = false
            }
          })
          if (flag) {
            this.selectedRows.push(data)
          }
        })
      } else {
        for (const item of changeRows) {
          for (let index = 0; index < this.selectedRows.length; index++) {
            if (item.order_num === this.selectedRows[index].order_num) {
              this.selectedRows.splice(index, 1)
            }
          }
        }
      }
    },
    onDateChange(date, dateString) {
      if (dateString[0]) {
        this.queryParam['begin_time'] = date[0].startOf('day').format('YYYY-MM-DD HH:mm:ss');
        this.queryParam['end_time'] = date[1].endOf('day').format('YYYY-MM-DD HH:mm:ss');
      } else {
        this.queryParam['begin_time'] = null;
        this.queryParam['end_time'] = null;
      }
    },
    // 导出代销月度统计表
    handleStaticsExport() {
      const orderNumList = [];
      this.selectedRows.forEach(v => orderNumList.push(v.order_num));
      if (orderNumList.length === 0) {
        this.$message.warning('请选择至少一条记录进行导出~');
        return;
      }
      this.queryParam['order_num_list'] = orderNumList;
      exportMonthlyStatisticsData(this.queryParam).then(res => {
        if (res !== null) {
          const fileInfo = { fileName: res }
          downloadExcel(fileInfo).then(downRes => {
            const data = downRes.data;
            let fileName = '';
            const contentDisposition = downRes.headers['content-disposition'];
            if (contentDisposition) {
              fileName = decodeURI(escape(contentDisposition.split('=')[1]))
            }
            const url = window.URL.createObjectURL(
              new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
            );
            const link = document.createElement('a');
            link.style.display = 'none';
            link.href = url;
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          });
        }
      }).finally(_ => {
        this.popVisible = false;
      });
    },
    // 导出对账单
    handleAgentInfoExport() {
      const orderNumList = [];
      this.selectedRows.forEach(v => orderNumList.push(v.order_num));
      if (orderNumList.length === 0) {
        this.$message.warning('请选择至少一条记录进行导出~');
        return;
      }
      this.queryParam['order_num_list'] = orderNumList;
      exportAgentInfoExport(this.queryParam).then(res => {
        if (res !== null) {
          const fileInfo = { fileName: res }
          downloadExcel(fileInfo).then(downRes => {
            const data = downRes.data;
            let fileName = '';
            const contentDisposition = downRes.headers['content-disposition'];
            if (contentDisposition) {
              fileName = decodeURI(escape(contentDisposition.split('=')[1]))
            }
            const url = window.URL.createObjectURL(
              new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
            );
            const link = document.createElement('a');
            link.style.display = 'none';
            link.href = url;
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          });
        }
      });
    },
    getFeeInfoTableDate(data) {
      this.totalAmount = 0;
      this.feeInfoData = data;
      if (data.length !== 0) {
        data.forEach(fee => {
          this.totalAmount += (fee.money * fee.fee_num);
        });
      }
    },
    choosePayTarget(record, payBillName) {
      const data = record.fee_info_list
      const fees = data.filter(item => item.fee_status >= 3 && item.fee_status !== 4)
      if (fees.length > 0) {
        this.payTarget = fees
        this.payBillName = payBillName
        this.orderData = record
        this.choosePayTargetVisible = true
      } else {
        this.$message.error('当前订单没有提交商务审核费用')
      }
    },
    // 打印
    subPrintPayBills() {
      if (this.payBillName === '付款单') {
        this.printPayFeeBill()
      } else if (this.payBillName === '付款对账单') {
        this.printPayFeeAccountBill()
      }
    },
    // 选择付款对象关闭
    choosePayTargetCancel() {
      this.pay_target_Data = {}
      this.orderData = {}
      this.payBillName = ''
      this.choosePayTargetVisible = false
    },
    // 打印付款对账单
    printPayFeeAccountBill() {
      const ids = []
      ids.push(this.orderData.id)
      const data = {
        ids: ids,
        pay_target_id: this.pay_target_Data.pay_target_id,
        fee_id: this.pay_target_Data.id
      }
      wmsSaleFinanceFunc('payFeeBill', data).then(v => {
        this.printData = v
        this.printPayStatementsBillModel = true
      })
    },
    // 打印付款单
    printPayFeeBill() {
      const ids = []
      ids.push(this.orderData.id)
      const data = {
        ids: ids,
        pay_target_id: this.pay_target_Data.pay_target_id,
        fee_id: this.pay_target_Data.id
      }
      wmsSaleFinanceFunc('payFeeBill', data).then(v => {
        // 费用名称相同的订单进行合并
        const data = v
        const mapItem = new Map()
        const result = []
        data.forEach(fee => {
          if (mapItem.has(fee.fee_name_type)) {
            mapItem.get(fee.fee_name_type).money += fee.money
          } else {
            mapItem.set(fee.fee_name_type, fee)
          }
        })
        for (const key of mapItem.keys()) {
          result.push(mapItem.get(key))
        }
        this.printData = result
        this.printPayBillModel = true
      })
    },
    // 打印弹窗
    billPrint(btnName) {
      document.getElementById(btnName).click()
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
    },
    handleVerifyOrAudit(operate) {
      // 业务审核
      const data = []
      this.selectedRows.forEach(v => {
        v.fee_info_list.forEach(fee => {
          switch (operate) {
            // 审核
            case 'pay_basic_audit':
              if (fee.fee_status === 1) {
                data.push({
                  bill_id: fee.bill_id,
                  bill_good_id: fee.bill_good_id,
                  bill_type: fee.bill_type
                });
              }
              break;
            // 反审核
            case 'pay_undo_audit':
              if (fee.fee_status === 2) {
                data.push({
                  bill_id: fee.bill_id,
                  bill_good_id: fee.bill_good_id,
                  bill_type: fee.bill_type
                });
              }
              break;
          }
        })
      })
      if (data.length) {
        wmsSaleFinanceChargeFunc(operate, data).then(_ => {
          this.$notification['success']({
            message: '提示',
            description: '操作成功'
          })
          this.$refs.table.refresh(false);

          // 操作成功后动态将选中的 row 数据更新方便操作
          this.selectedRows.forEach(v => {
            if (operate === 'pay_basic_audit') {
              // 审核
              v.fee_info_list.forEach(fee => {
                fee.fee_status = 2
              });
            } else {
              // 反审核
              v.fee_info_list.forEach(fee => {
                fee.fee_status = 1
              });
            }
          });
        });
      } else {
        this.$message.warning(operate === 'pay_basic_audit' ? '请选择已对账待审核的数据' : '请选择业务已审核的数据');
      }
    },
    handleApplyBizAudit() {
      // 提交商务审核
      this.checkedList = []
      const feeMaps = []
      let flag = true
      let total = 0
      this.selectedRows.forEach(v => {
        v.fee_info_list && v.fee_info_list.map(e => {
          if (e.fee_status !== 2 && e.fee_status !== 4) {
            flag = false
          }
        })
      })
      if (flag) {
        this.selectedRows.forEach(v => {
        const orderFee = {
          orderId: v.order_num,
          feeList: v.fee_info_list && v.fee_info_list.filter(e => e.fee_status === 2 || e.fee_status === 4).map(e => {
              total += e.money ? e.money : 0
              // if (e.fee_status === 2 || e.fee_status === 4) {
              //   flag = true
              // }
              this.checkedList.push(e.id);
              return {
                id: e.id,
                fee_num: e.fee_num,
                bill_id: e.bill_id,
                bill_good_id: e.bill_good_id,
                bill_type: e.bill_type,
                feeName: e.good_name + '/' + e.unit + '/' + e.good_spec,
                money: e.total_money,
                good_num: e.good_num,
                pay_target_id: e.pay_target_id,
                charge_type: e.charge_type,
                fee_status: e.fee_status,
                fee_name_type: e.fee_name_type,
                invoice_num: e.invoice_num
              }
            })
        }
        feeMaps.push(orderFee);
        this.feeInfoData = this.feeInfoData.concat(orderFee.feeList);
      })
        this.totalAmount = total
        this.feeOrderList = feeMaps
        getCommonNoPage('invoice_registration', {}).then((v) => {
          this.invoiceRegOps = v
        })
        let idList = []
        this.selectedRows.map(v => {
          idList = v.customer_ids.split(',')
        })
        getLeaseInfoListByDepartment({ 'department': 8, 'customer_id_list': idList }).then(v => {
          this.leaseInfoList = v
        })
        this.applyFormVisible = true
      } else {
        this.$message.warning('没有可提交的费用,费用状态必须为业务已审核或商务审核被驳回')
      }
    },
    handleApplyFormCancel() {
      this.applyFormVisible = false;
      this.form.invoice_num = [];
      this.form.appendix_num = null;
      this.form.is_advance = 1;
      this.feeInfoData = [];
    },
    handleApplyFormSubmit() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.confirmLoading = true;
          wmsSaleFinanceChargeFunc('business_audit', {
            pay_method: this.form.pay_method,
            apply_remark: this.form.apply_remark,
            fee_info_list: this.feeInfoData,
            appendix_num: this.form.appendix_num,
            invoice_num: this.form.invoice_num,
            is_advance: this.form.is_advance,
            contract_list: this.form.contract_list
          }).then(_ => {
            this.$notification['success']({
              message: '提示',
              description: '操作成功'
            });
            this.selectedRows = [];
            this.selectedRowKeys = [];
            this.$refs.table.refresh(false);
            this.handleApplyFormCancel();
          }).finally(_ => {
            this.confirmLoading = false;
          });
        }
      });
    },
    onChange(checkedValues) {
      let count = 0
      this.totalAmount = 0
      for (const item of this.feeOrderList) {
        count += item.feeList.length
        for (let i = 0; i < checkedValues.length; i++) {
          for (let j = 0; j < item.feeList.length; j++) {
            if (checkedValues[i] === item.feeList[j].id) {
              this.totalAmount += item.feeList[j].money
            }
          }
        }
      }
      this.indeterminate = !!this.checkedList.length && this.checkedList.length < count
      this.checkAll = this.checkedList.length === count
    },
    onCheckAllChange(e) {
      this.checkAll = e.target.checked
      this.indeterminate = false
      this.checkedList = []
      this.totalAmount = 0
      if (e.target.checked) {
        this.feeOrderList.forEach(item => {
          for (const e of item.feeList) {
            this.totalAmount += e.money
            this.checkedList.push(e.id)
          }
        })
      }
    },
    handleExport() {
      exportWmsSaleFinance('export_pay_data', this.queryParam).then(res => {
        if (res !== null) {
          const fileInfo = {
            fileName: res
          }
          downloadExcel(fileInfo).then(downRes => {
            const data = downRes.data
            let fileName = ''
            const contentDisposition = downRes.headers['content-disposition']
            if (contentDisposition) {
              fileName = decodeURI(escape(contentDisposition.split('=')[1]))
            }
            const url = window.URL.createObjectURL(
              new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
            )
            const link = document.createElement('a')
            link.style.display = 'none'
            link.href = url
            link.setAttribute('download', fileName)
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
          })
        }
      })
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
        getFirstLetter(option.componentOptions.children[0].text)
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      )
    },
    feeStatusColor(value) {
      switch (value) {
        case 1:
          return '#faad14'
        case 2:
          return '#1890ff'
        case 3:
          return '#4cd964'
        case 4:
          return '#ff4d4f'
        default:
          return '#4cd964'
      }
    },
    payMethodToRemark() {
      if (this.form.pay_method === 7) {
        this.form.apply_remark = '无需付款，对应成本为0，请调整账面成本'
      } else {
        this.form.apply_remark = '';
      }
    }
  }
}
</script>
